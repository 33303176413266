.angle-30 {
  top: -300px;
}
.angled-bg {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 2400px;
  clip-path: polygon(0 40%, 0 0, 100% 50%, 100% 90%);
  /* background-color: #f4f4f4; */
  background-color: #ffe3f2;
  z-index: -2;
}
