.banner3 {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../../Assets/family.jpg") center/cover no-repeat;
}

.banner3::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
