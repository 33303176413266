.banner2 {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../../../Assets/parlour.jpg") center/cover no-repeat;
  }
  
  .banner2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.5) 90%
    );
    opacity: 0.9;
    pointer-events: none; /* Ensure the overlay doesn't interfere with interactions */
  }
  
  